<template>
  <div>
    <div class="card bg-white">
      <div class="mb-4 flex justify-content-between align-items-center">
        <div class="flex justify-content-end mb-3">
          <div
            :class="
              'status-badge status-' +
              form.state.toLowerCase().replace(' ', '-')
            "
          >
            {{ form.state.toUpperCase() }}
          </div>
        </div>
        <div class="flex justify-content-end mb-2">
          <Button
            v-show="form.state == 'dalam pengajuan' && this.role == 'ketua'"
            :loading="isLoadingApprove"
            icon="pi pi-check"
            class="p-button-success mr-2"
            label="approve"
            severity="success"
            @click="dialogApprove = true"
          />
          <Button
            v-show="form.state == 'dalam pengajuan' && this.role == 'ketua'"
            :loading="isLoadingReject"
            icon="pi pi-times"
            class="p-button-danger mr-2"
            label="reject"
            severity="success"
            @click="dialogReject = true"
          />
          <Button
            v-show="form.status === 'pengajuan' && form.state == 'draft'"
            :loading="isLoadingPengajuan"
            icon="pi pi-check"
            class="p-button-success mr-2"
            label="pengajuan"
            severity="success"
            @click="dialogPengajuan = true"
          />
          <Button
            v-show="form.state == 'approved'"
            :loading="isLoadingBayar"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Bayar"
            @click="onAddDataBayar"
          />
          <Button
            v-if="form.status == 'draft'"
            :disabled="old_status === 'pengajuan'"
            :loading="isLoadingSave"
            icon="pi pi-save"
            class="mr-2"
            :label="id > 0 ? 'Update' : 'Simpan'"
            @click="onConfirmSave(!v$.$invalid)"
          />
          <Button
            v-if="form.state == 'draft'"
            :disabled="old_status === 'pengajuan'"
            icon="pi pi-plus"
            class="p-button-outlined mr-2"
            label="Item"
            @click="onAddData"
          />
          <Button
            class="p-button-link"
            label="Kembali"
            @click="$router.push({ name: 'Pinjaman' })"
          />
        </div>
      </div>
      <div class="grid">
        <div class="col-10 md:col-8">
          <div class="formgrid grid">
            <div class="field col-12 md:col-3">
              <label>No Pinjaman</label>
              <InputText
                v-model="form.pi_no"
                class="w-full"
                input-class="w-full"
                maxlength="50"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Pengajuan</label>
              <Calendar
                v-model="form.pi_at_pengajuan"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Approve/Reject</label>
              <Calendar
                v-model="form.pi_at_approve_reject"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-3">
              <label>Tgl Pinjaman</label>
              <Calendar
                :disabled="
                  old_status === 'pengajuan' ||
                  old_status === 'rejected' ||
                  old_status === 'ongoing'
                "
                v-model="form.pi_at"
                dateFormat="dd/mm/yy"
                class="w-full"
                input-class="w-full"
              />
            </div>
            <div class="field col-12 md:col-6">
              <label>Anggota</label>
              <AutoComplete
                ref="item"
                v-model="form.anggota"
                class="w-full"
                input-class="w-full"
                :min-length="2"
                :suggestions="acAnggota"
                :field="anggotaLabel"
                :autofocus="!id > 0"
                :class="{
                  'p-invalid': v$.form.anggota.$invalid && submitted,
                }"
                :disabled="
                  old_status === 'pengajuan' ||
                  old_status === 'rejected' ||
                  old_status === 'ongoing'
                "
                placeholder="Search Anggota"
                @complete="searchAnggota($event)"
                @item-select="selectAnggota"
                @focus="selectAllText('item')"
              >
                <template #item="slotProps">
                  <div v-if="slotProps.item">
                    <div>
                      <strong>
                        {{ slotProps.item.kode }} -
                        {{ slotProps.item.nama }}
                      </strong>
                    </div>
                    <div>Alamat: {{ slotProps.item.alamat }}</div>
                  </div>
                  <span v-else>
                    {{ slotProps.placeholder }}
                  </span>
                </template>
              </AutoComplete>
              <small
                v-if="
                  (v$.form.anggota.$invalid && submitted) ||
                  v$.form.anggota.$pending.$response
                "
                class="p-error"
                >{{ v$.form.anggota.required.$message }}</small
              >
            </div>

            <div class="field col-12 md:col-6">
              <label>Jenis Pinjaman</label>
              <Dropdown
                v-model="form.jpin_id"
                :options="list_jpin"
                optionValue="id"
                optionLabel="nama"
                class="w-full"
                input-class="w-full"
                disabled
              >
              </Dropdown>
            </div>
            <div class="field col-12 md:col-3">
              <label>Jumlah Pinjaman</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.pokok"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-3">
              <label>Jangka Waktu </label>
              <div class="p-inputgroup">
                <Dropdown
                  v-model="form.jangka_id"
                  :options="list_jangka"
                  optionValue="id"
                  :optionLabel="jangkaLabel"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-3">
              <label>Bunga</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="form.bunga"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-12 md:col-3">
              <label>Status</label>
              <Dropdown
                v-model="form.status"
                :options="list_status"
                optionValue="value"
                optionLabel="label"
                optionDisabled="disabled"
                class="w-full"
                input-class="w-full"
                :disabled="
                  old_status === 'pengajuan' ||
                  old_status === 'rejected' ||
                  old_status === 'ongoing'
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <fx-table
          :items="form.details"
          :total="form.details.length"
          :loading="isLoading"
          :paginator="false"
          hasFooter
          showGridlines
        >
          <template #columns>
            <Column
              v-if="form.state == 'approved'"
              field="tanggal_bayar"
              header="tgl pembayaran"
              style="min-width: 5rem; background-color: #f8f9fa"
            >
              <template #body="slotProps">
                {{ formatDate(slotProps.data.tanggal_bayar) }}
              </template>
            </Column>
            <Column
              field="tanggal_jatuh_tempo"
              header="tgl jatuh tempo"
              style="min-width: 5rem; background-color: #f8f9fa"
            >
              <template #body="slotProps">
                {{ formatDate(slotProps.data.tanggal_jatuh_tempo) }}
              </template>
            </Column>
            <Column
              field="angsuran_ke"
              header="angs ke"
              style="
                max-width: 5rem;
                background-color: #f8f9fa;
                justify-content: center;
              "
            />
            <Column
              field="angsuran_pokok"
              header="angs pokok"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.angsuran_pokok) }}
                </span>
              </template>
            </Column>
            <Column
              field="bunga"
              header="bunga"
              style="max-width: 5rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ slotProps.data.bunga }} %
                </span>
              </template>
            </Column>
            <Column
              field="bunga_v"
              header="nilai bunga"
              style="max-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.bunga_v) }}
                </span>
              </template>
            </Column>
            <Column
              field="angsuran_perbulan"
              header="angs perbulan"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.angsuran_perbulan) }}
                </span>
              </template>
            </Column>
            <Column
              v-if="form.state == 'approved'"
              field="denda"
              header="denda"
              style="max-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.denda) }}
                </span>
              </template>
            </Column>
            <Column
              v-if="form.state == 'approved'"
              field="status"
              header="status"
              style="max-width: 6rem; justify-content: center"
              :showFilterMenu="false"
            >
              <template #body="{ data }">
                <div :class="'status-badge status-' + data.status">
                  {{ data.status }}
                </div>
              </template>
            </Column>
            <Column
              v-if="form.state == 'approved'"
              field="bayar"
              header="pembayaran"
              style="min-width: 10rem; justify-content: flex-end"
            >
              <template #body="slotProps">
                <span style="text-align: right">
                  {{ formatCurrency(slotProps.data.bayar) }}
                </span>
              </template>
            </Column>
          </template>
          <template #footer>
            <div class="p-d-flex p-jc-end p-ai-center" style="width: 100%">
              <table style="width: 100%">
                <tr>
                  <td style="text-align: right">
                    {{ formatCurrency(totalPinjaman.total || 0) }}
                  </td>
                </tr>
              </table>
            </div>
          </template>
        </fx-table>
      </div>
    </div>
    <!-- </form> -->
    <Dialog
      :header="editMode ? 'Edit Item Pinjaman' : 'Tambah Item Pinjaman'"
      v-model:visible="dialog"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '40vw' }"
      :modal="true"
    >
      <form-pinjaman-item
        :item="formItem"
        :loading="isLoadingSave"
        @save="onSaveData"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Pembayaran"
      v-model:visible="dialogBayar"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '30vw' }"
      :modal="true"
    >
      <form-pinjaman-bayar
        :item="formBayar"
        :loading="isLoadingBayar"
        @save="onConfirmBayar"
        @close="dialog = false"
      />
    </Dialog>
    <Dialog
      header="Konfirmasi Pinjaman"
      v-model:visible="dialogItem"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '35vw' }"
      :modal="true"
      @close="dialogItem = false"
    >
      <div class="confirmation-content">
        <span>Item detail pinjaman sudah ada. Buat ulang ?</span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogItem = false"
          class="p-button-text mr-2"
        />
        <Button
          label="Buat ulang"
          icon="pi pi-plus"
          @click="onDeleteItemPinjaman"
          class="p-button-outlined mr-2"
        />
      </template>
    </Dialog>
    <Dialog
      header="Konfirmasi Pengajuan Pinjaman"
      v-model:visible="dialogPengajuan"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '35vw' }"
      :modal="true"
      @close="dialogPengajuan = false"
    >
      <div class="confirmation-content">
        <span>Apakah anda yakin akan mengajukan pinjaman ?</span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogPengajuan = false"
          class="p-button-text mr-2"
        />
        <Button
          :loading="isLoadingPengajuan"
          label="Ajukan"
          icon="pi pi-check"
          @click="onConfirmPengajuan"
          class="p-button-success mr-2"
        />
      </template>
    </Dialog>
    <Dialog
      header="Konfirmasi Approve Pinjaman"
      v-model:visible="dialogApprove"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '35vw' }"
      :modal="true"
      @close="dialogApprove = false"
    >
      <div class="confirmation-content">
        <span>Apakah anda yakin akan menyetujui pinjaman ?</span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogApprove = false"
          class="p-button-text mr-2"
        />
        <Button
          :loading="isLoadingApprove"
          label="approve"
          icon="pi pi-check"
          @click="onConfirmApprove"
          class="p-button-success mr-2"
        />
      </template>
    </Dialog>
    <Dialog
      header="Konfirmasi Reject Pinjaman"
      v-model:visible="dialogReject"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '35vw' }"
      :modal="true"
      @close="dialogReject = false"
    >
      <div class="confirmation-content">
        <span>Apakah anda yakin akan menolak pinjaman ?</span>
      </div>
      <template #footer>
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="dialogReject = false"
          class="p-button-text mr-2"
        />
        <Button
          :loading="isLoadingReject"
          label="reject"
          icon="pi pi-times"
          @click="onConfirmReject"
          class="p-button-danger mr-2"
        />
      </template>
    </Dialog>
    <hotkey :shortcuts="['D', 'S']" @triggered="onTriggerHotkey" />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import useVuelidate from '@vuelidate/core'
import errorHandler from '@/helpers/error-handler'
import FxTable from '@/components/FxTable'
import { helpers, required } from '@vuelidate/validators'
import { formatCurrency, formatDate } from '@/helpers/index'
import AnggotaService from '@/services/AnggotaService'
import PinjamanService from '@/services/PinjamanService'
import JpinService from '@/services/JpinService'
import JangkaService from '@/services/JangkaService'
import FormPinjamanItem from '@/components/pinjaman/FormPinjamanItem'
import FormPinjamanBayar from '@/components/pinjaman/FormPinjamanBayar'
import Hotkey from '@/components/Hotkey'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    FormPinjamanItem,
    FormPinjamanBayar,
    FxTable,
    Hotkey,
  },
  data() {
    return {
      submitted: false,
      anggotaService: null,
      pinjamanService: null,
      jangkaService: null,
      jpinService: null,
      acAnggota: null,
      role: null,
      dialog: false,
      dialogItem: false,
      dialogPengajuan: false,
      dialogBayar: false,
      dialogApprove: false,
      dialogReject: false,
      isLoading: false,
      isLoadingSave: false,
      isLoadingPengajuan: false,
      isLoadingBayar: false,
      isLoadingApprove: false,
      isLoadingReject: false,
      items: [],
      list_jpin: [],
      list_jangka: [],
      editMode: false,
      form: {
        pi_no: '',
        pi_at: new Date(),
        anggota_id: 0,
        status: 'draft',
        state: 'draft',
        details: [],
      },
      formItem: {
        jpin_id: '',
        jumlah_pinjaman: 0,
        jangka_id: 0,
        bunga: 0,
        bunga_v: 0,
        angsuran_pokok: 0,
        angsuran_perbulan: 0,
        total_pinjaman: 0,
      },
      formBayar: {
        anggota_id: 0,
        angsuran_ke: '',
        anggota_pokok: 0,
        anggota_perbulan: 0,
        denda: 0,
        bayar: 0,
      },
      list_status: [
        { value: 'draft', label: 'draft' },
        { value: 'pengajuan', label: 'pengajuan' },
      ],
      old_status: 'draft',
      listDeleted: [],
    }
  },
  created() {
    this.anggotaService = new AnggotaService()
    this.pinjamanService = new PinjamanService()
    this.jangkaService = new JangkaService()
    this.jpinService = new JpinService()
  },
  computed: {
    totalPinjaman() {
      // jumlah
      let jumlah = this.form.details.reduce((total, detail) => {
        total += detail.angsuran_perbulan
        return total
      }, 0)

      const total = {
        total: jumlah,
      }
      return total
    },
  },
  mounted() {
    if (this.id > 0) {
      this.isLoading = true
      this.pinjamanService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.old_status = this.form.status
            this.form.pi_at = this.form.pi_at ? new Date(this.form.pi_at) : null
            this.form.pi_at_pengajuan = this.form.pi_at_pengajuan
              ? new Date(this.form.pi_at_pengajuan)
              : null
            this.form.pi_at_approve_reject = this.form.pi_at_approve_reject
              ? new Date(this.form.pi_at_approve_reject)
              : null

            // Retrieve and parse the user from localStorage
            const user = localStorage.getItem('user')
            if (user) {
              try {
                const parsedUser = JSON.parse(user) // Parse the user data
                this.role = parsedUser.role // Access the role property
              } catch (e) {
                console.error('Error parsing user data from localStorage', e)
                this.role = null // Set role to null in case of error
              }
            }
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => (this.isLoading = false))
    }

    this.jpinService
      .get()
      .then((res) => {
        this.list_jpin = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Jenis Pinjaman', this)
      })
    this.jangkaService
      .get()
      .then((res) => {
        this.list_jangka = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Jangka Waktu', this)
      })
  },
  methods: {
    formatDate(value) {
      return formatDate(value)
    },
    formatCurrency(val) {
      return formatCurrency(val, 0, 0)
    },
    searchAnggota(e) {
      this.anggotaService
        .get(`?filter[nama]=${e.query}&filter[status]=active`)
        .then((res) => {
          this.acAnggota = res.data.data
        })
        .catch((err) => {
          errorHandler(err, 'Data Anggota', this)
        })
    },
    selectAnggota(e) {
      this.form.anggota_id = e.value.id
      this.anggotaLabel(e)
    },
    anggotaLabel(e) {
      return e.kode + ' - ' + e.nama
    },
    jangkaLabel(e) {
      return e.nilai + ' Bulan'
    },
    selectAllText(event) {
      if (event) {
        this.$refs[event].$el.querySelector('input').select()
      }
    },
    loadData() {
      this.pinjamanService
        .get(`/${this.id}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.form = res.data.data
            this.old_status = this.form.status
            this.form.pi_at = this.form.pi_at ? new Date(this.form.pi_at) : null
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => (this.isLoading = false))
    },
    onAddData() {
      //   if (!this.form.anggota_id) {
      //     this.$toast.add({
      //       severity: 'warn',
      //       summary: 'Pinjaman',
      //       detail: 'Anggota harus diisi terlebih dahulu.',
      //       life: 3000,
      //     })
      //     return
      //   }

      if (this.form.details.length > 0) {
        this.dialogItem = true
      } else {
        this.editMode = false
        this.dialog = true
      }
    },
    async onEditData(data) {
      this.editMode = true
      this.formItem = Object.assign({}, data)
      this.dialog = true
    },
    onAddDataBayar() {
      const outstandingDetails = this.form.details.filter(
        (detail) => detail.status === 'outstand'
      )
      outstandingDetails.sort((a, b) => a.id - b.id)
      const data = outstandingDetails.length > 0 ? outstandingDetails[0] : {}

      this.formBayar = Object.assign({}, data)
      this.dialogBayar = true
    },
    async onSaveData(data) {
      const detailItem = Object.assign({}, data)
      const payload = []
      const currentDate = new Date()

      if (this.editMode) {
        Object.assign({ ...detailItem })
        this.dialog = this.editMode ? false : this.dialog
      } else {
        // header

        this.form.jpin_id = detailItem.jpin_id
        this.form.jangka_id = detailItem.jangka_id
        this.form.pokok = detailItem.jumlah_pinjaman
        this.form.bunga = detailItem.bunga

        // details
        for (let i = 1; i <= detailItem.jangka_nilai; i++) {
          const nextMonth = new Date(currentDate)
          nextMonth.setMonth(nextMonth.getMonth() + i)

          payload.push({
            tanggal_bayar: null,
            tanggal_jatuh_tempo: dayjs(nextMonth).format('YYYY-MM-DD'),
            angsuran_ke: i,
            angsuran_pokok: detailItem.angsuran_pokok,
            angsuran_perbulan: detailItem.angsuran_perbulan,
            bunga: detailItem.bunga,
            bunga_v: detailItem.bunga_v,
            denda: 0,
            bayar: 0,
            status: 'draft',
          })
        }

        this.form.details = payload
        this.$toast.add({
          severity: 'success',
          summary: 'Pinjaman',
          detail: 'Item ' + payload.nama_pinjaman + ' berhasil ditambahkan.',
          life: 3000,
        })
        this.formItem = {}
        this.dialog = false
      }
    },
    onConfirmDeletion(data) {
      this.formItem = data
      this.dialogItem = true
    },
    onDeleteItemPinjaman() {
      this.form.details = []
      this.dialogItem = false
      this.dialog = true
    },
    onConfirmSave(isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        return
      }

      if (this.form.details.length === 0) {
        this.$toast.add({
          severity: 'warn',
          summary: 'Pinjaman',
          detail: 'Item pinjaman minimal 1 data.',
          life: 3000,
        })
        return
      }

      const deleted = this.id ? { deleted: this.listDeleted } : {}

      const pi_at = this.form.pi_at
        ? { pi_at: dayjs(this.form.pi_at).format('YYYY-MM-DD') }
        : null
      const pi_at_pengajuan = this.form.pi_at_pengajuan
        ? {
            pi_at_pengajuan: dayjs(this.form.pi_at_pengajuan).format(
              'YYYY-MM-DD'
            ),
          }
        : null
      const pi_at_approve_reject = this.form.pi_at_approve_reject
        ? {
            pi_at_approve_reject: dayjs(this.form.pi_at_approve_reject).format(
              'YYYY-MM-DD'
            ),
          }
        : null
      const form = {
        ...pi_at,
        ...pi_at_pengajuan,
        ...pi_at_approve_reject,
        status: this.form.status,
        pi_no: this.form.pi_no,
        anggota_id: this.form.anggota_id,
        jpin_id: this.form.jpin_id,
        jangka_id: this.form.jangka_id,
        pokok: this.form.pokok,
        details: this.form.details,
        ...deleted,
      }

      if (!this.id) {
        this.isLoadingSave = true
        this.pinjamanService
          .add(form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pinjaman',
                detail: 'Data berhasil disimpan.',
                life: 3000,
              })
              this.$router.push({ name: 'Pinjaman' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pinjaman', this)
          })
          .finally(() => {
            this.deleted = []
            this.isLoadingSave = false
          })
      } else {
        this.isLoadingSave = true
        this.pinjamanService
          .update(this.id, form)
          .then((res) => {
            if (res.data.status === 200) {
              this.$toast.add({
                severity: 'success',
                summary: 'Pinjaman',
                detail: 'Data berhasil diupdate.',
                life: 3000,
              })
              this.$router.push({ name: 'Pinjaman' })
            }
          })
          .catch((err) => {
            errorHandler(err, 'Pinjaman', this)
          })
          .finally(() => {
            this.isLoadingSave = false
          })
      }
    },
    async onConfirmBayar(data) {
      this.isLoadingBayar = true
      const detailBayar = {
        anggota_id: data.anggota_id,
        angsuran_ke: data.angsuran_ke,
        angsuran_pokok: data.angsuran_pokok,
        angsuran_perbulan: data.angsuran_perbulan,
        bayar: data.bayar,
        denda: data.denda,
      }

      this.pinjamanService
        .bayar(this.id, detailBayar)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pinjaman',
              detail: 'Data berhasil diajukan.',
              life: 3000,
            })
            this.dialogBayar = false
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => {
          this.isLoadingBayar = false
        })
    },
    onConfirmPengajuan() {
      this.isLoadingPengajuan = true
      this.form.state = 'dalam pengajuan'
      this.form.pi_at_pengajuan = new Date()
      this.form.pi_at = dayjs(this.form.pi_at).format('YYYY-MM-DD')
      this.form.pi_at_pengajuan = dayjs(this.form.pi_at_pengajuan).format(
        'YYYY-MM-DD'
      )
      this.form.details.forEach((detail) => {
        detail.tanggal_jatuh_tempo = dayjs(detail.tanggal_jatuh_tempo).format(
          'YYYY-MM-DD'
        )
      })
      this.pinjamanService
        .update(this.id, this.form)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pinjaman',
              detail: 'Data berhasil diajukan.',
              life: 3000,
            })
            this.$router.push({ name: 'Pinjaman' })
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => {
          this.deleted = []
          this.isLoadingPengajuan = false
        })
    },
    onConfirmApprove() {
      this.isLoadingApprove = true
      this.pinjamanService
        .approve(this.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pinjaman',
              detail: 'Data berhasil disetujui.',
              life: 3000,
            })
            this.form.status = res.data.data.status
            this.form.state = res.data.data.state
            this.dialogApprove = false
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => {
          this.deleted = []
          this.isLoadingApprove = false
        })
    },
    onConfirmReject() {
      this.isLoadingReject = true
      this.pinjamanService
        .reject(this.id)
        .then((res) => {
          if (res.data.status === 200) {
            this.$toast.add({
              severity: 'success',
              summary: 'Pinjaman',
              detail: 'Data berhasil ditolak.',
              life: 3000,
            })
            this.form.state = res.data.data.state
            this.dialogReject = false
            this.loadData()
          }
        })
        .catch((err) => {
          errorHandler(err, 'Pinjaman', this)
        })
        .finally(() => {
          this.deleted = []
          this.isLoadingReject = false
        })
    },
    onTriggerHotkey(payload) {
      switch (payload.keyString) {
        case 'D':
          this.add()
          break
        case 'S':
          this.save(!this.v$.$invalid)
          break
      }
    },
  },
  validations() {
    return {
      form: {
        pi_at: {
          required: helpers.withMessage('Tgl Pinjaman harus diisi.', required),
        },
        anggota: {
          required: helpers.withMessage('Anggota harus diisi.', required),
        },
      },
    }
  },
}
</script>


<style scoped>
.p-inputgroup-addon {
  white-space: nowrap;
}

.p-inputgroup > .p-inputtext,
.p-inputgroup > .p-dropdown {
  flex-grow: 1;
}
</style>