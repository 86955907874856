<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Jenis pinjaman </label>
              <Dropdown
                v-model="form.jpin_id"
                :options="list_jpin"
                :filter="true"
                optionValue="id"
                optionLabel="nama"
                placeholder="Pilih Jenis pinjaman"
                class="w-full"
                input-class="w-full"
                :showClear="true"
                :class="{ 'p-invalid': v$.form.jpin_id.$invalid && submitted }"
                @change="onChangeJpin"
              >
                <small
                  v-if="
                    (v$.form.jpin_id.$invalid && submitted) ||
                    v$.form.jpin_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jpin_id.required.$message }}</small
                >
              </Dropdown>
            </div>
            <div class="field col-12 md:col-12">
              <label>Jumlah Pinjaman</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="jumlahPinjaman"
                  v-model="form.jumlah_pinjaman"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  :min-fraction-digits="0"
                  :max-fraction-digits="0"
                  :allow-empty="false"
                  :class="{ 'p-invalid': v$.form.jumlah_pinjaman.$invalid && submitted }"
                  @focus="selectAllText"
                  @input="inputJumlah"
                />
                <small
                  v-if="
                    (v$.form.jumlah_pinjaman.$invalid && submitted) ||
                    v$.form.jumlah_pinjaman.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jumlah_pinjaman.required.$message }}</small
                >
              </div>
            </div>
            <div class="field col-4 md:col-4">
              <label>Jangka Waktu </label>
              <div class="p-inputgroup">
                <Dropdown
                  v-model="form.jangka_id"
                  :options="list_jangka_filtered"
                  :filter="true"
                  optionValue="id"
                  :optionLabel="jangkaLabel"
                  placeholder="Pilih Jangka Waktu"
                  class="w-full"
                  input-class="w-full"
                  :class="{ 'p-invalid': v$.form.jangka_id.$invalid && submitted }"
                  @change="onChangeJangka"
                />
                 <small
                  v-if="
                    (v$.form.jangka_id.$invalid && submitted) ||
                    v$.form.jangka_id.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.jangka_id.required.$message }}</small
                >
              </div>
            </div>
            <div class="field col-4 md:col-4">
              <label>Bunga</label>
              <div class="p-inputgroup">
                <InputText
                  v-model="form.bunga"
                  class="w-full"
                  input-class="w-full"
                  disabled
                />
                <span class="p-inputgroup-addon">%</span>
              </div>
            </div>
            <div class="field col-4 md:col-4">
              <label>Nilai Bunga</label>
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.bunga_v"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="border-bottom-1 border-400 mb-3" />
          <div class="formgrid grid">
            <div class="field col-6 md:col-6">
              <label>Angsuran Pokok</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.angsuran_pokok"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-6 md:col-6">
              <label>Angsuran Perbulan</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.angsuran_perbulan"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Total Pinjaman</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.total_pinjaman"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          :label="editMode ? 'Ubah' : 'Tambahkan'"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { ceiling } from '@/helpers/index'
import errorHandler from '@/helpers/error-handler'
import JpinService from '@/services/JpinService'
import JangkaService from '@/services/JangkaService'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitted: false,
      jpinService: null,
      list_jpin: [],
      list_jangka: [],
      list_jangka_filtered: [],
    }
  },
  created() {
    this.jpinService = new JpinService()
    this.jangkaService = new JangkaService()
  },
  async mounted() {
    this.jpinService
      .get()
      .then((res) => {
        this.list_jpin = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Jenis pinjaman', this)
      })
      .finally(() => (this.isLoading = false))

    this.jangkaService
      .get()
      .then((res) => {
        this.list_jangka = res.data.data
      })
      .catch((err) => {
        errorHandler(err, 'Data Jangka Waktu', this)
      })
      .finally(() => (this.isLoading = false))
  },
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event.target) {
        event.target.select()
      }
    },
    jangkaLabel(e) {
      return e.nilai + ' Bulan'
    },
    onChangeJpin(event) {
      this.list_jangka.forEach((item) => {
        if (item.jpin_id == event.value) {
          this.form.jumlah_pinjaman = 0
          this.form.jangka_id = null
          this.form.bunga = 0
          this.form.bunga_v = 0
          this.form.angsuran_pokok = 0
          this.form.angsuran_perbulan = 0
          this.form.total_pinjaman = 0
          this.list_jangka_filtered.push(item)
        }
      })
    },
    onChangeJangka(event) {
      this.list_jangka_filtered.forEach((item) => {
        if (item.id == event.value) {
          this.form.bunga = item.bunga
          const jangka = this.list_jangka_filtered.find(
            (item) => item.id === this.form.jangka_id
          )
          if (jangka) {
            this.form.jangka_nilai = jangka.nilai
            this.calculator(this.form.jumlah_pinjaman, jangka.nilai)
          }
        }
      })
    },
    inputJumlah(event) {
      if (this.form.jangka_id) {
        const jangka = this.list_jangka_filtered.find(
          (item) => item.id === this.form.jangka_id
        )
        if (jangka) {
            this.form.jangka_nilai = jangka.nilai
          this.calculator(event.value, jangka.nilai)
        }
      }
    },
    calculator(jumlahPinjaman, jangkaNilai) {
      // hitung angsuran pokok perbulan tanpa bunga
      const angsuranPokok = jumlahPinjaman / jangkaNilai
      this.form.angsuran_pokok = ceiling(angsuranPokok)

      // hitung bunga
      const bunga = this.form.jumlah_pinjaman * (this.form.bunga / 100)
      this.form.bunga_v = bunga

      // hitung angsuran perbulan + bunga
      const angsuranPerbulan = ceiling(angsuranPokok) + ceiling(bunga)
      this.form.angsuran_perbulan = angsuranPerbulan

      // total pinjaman
      this.form.total_pinjaman = angsuranPerbulan * jangkaNilai
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        jpin_id: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
        jumlah_pinjaman: {
          required: helpers.withMessage('Jumlah Pinjaman harus diisi.', required),
        },
        jangka_id: {
          required: helpers.withMessage('Jangka harus diisi.', required),
        },
      },
    }
  },
}
</script>


<style scoped>
.p-inputgroup-addon {
  white-space: nowrap;
}

.p-inputgroup > .p-inputtext,
.p-inputgroup > .p-dropdown {
  flex-grow: 1;
}
</style>