<template>
  <div>
    <!-- Untuk mencegah tekan enter langsung submit -->
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
      <div class="grid">
        <div class="col-12">
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Angsuran Ke </label>
              <InputText
                v-model="form.angsuran_ke"
                class="w-full"
                input-class="w-full"
                disabled
              />
            </div>
            <div class="field col-12 md:col-12">
              <label>Angsuran Pokok</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.angsuran_pokok"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Angsuran Perbulan</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.angsuran_pokok"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Denda</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.denda"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
            <div class="field col-12 md:col-12">
              <label>Total Pembayaran</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  v-model="form.angsuran_perbulan"
                  class="w-full"
                  input-class="w-full"
                  locale="id-ID"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="border-bottom-1 border-400 mb-3" />
          <div class="formgrid grid">
            <div class="field col-12 md:col-12">
              <label>Jumlah Pembayaran</label>
              <div class="p-inputgroup flex-1">
                <span class="p-inputgroup-addon"> Rp </span>
                <InputNumber
                  ref="item"
                  v-model="form.bayar"
                  class="w-full"
                  input-class="w-full"
                  placeholder="0"
                  locale="id-ID"
                  :class="{ 'p-invalid': v$.form.bayar.$invalid && submitted }"
                  @focus="selectAllText"
                />
                <small
                  v-if="
                    (v$.form.bayar.$invalid && submitted) ||
                    v$.form.bayar.$pending.$response
                  "
                  class="p-error"
                  >{{ v$.form.bayar.required.$message }}</small
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-content-end align-items-end">
        <Button
          label="Tutup"
          icon="pi pi-times"
          @click="$emit('close')"
          class="p-button-text mr-2"
        />
        <Button
          :loading="loading"
          label="Bayar"
          icon="pi pi-check"
          type="submit"
        />
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      submitted: false,
    }
  },
  created() {},
  async mounted() {},
  computed: {
    form() {
      return this.item
    },
  },
  methods: {
    selectAllText(event) {
      if (event.target) {
        event.target.select()
      }
    },
    handleSubmit(isFormValid) {
      this.submitted = true
      if (!isFormValid) {
        return
      }
      this.$emit('save', this.form)
    },
  },
  validations() {
    return {
      form: {
        bayar: {
          required: helpers.withMessage('Nama harus diisi.', required),
        },
      },
    }
  },
}
</script>


<style scoped>
.p-inputgroup-addon {
  white-space: nowrap;
}

.p-inputgroup > .p-inputtext,
.p-inputgroup > .p-dropdown {
  flex-grow: 1;
}
</style>